<template>

<div class="head" :class="{'is-selecting': selecting}">

	<div class="head-select">

		<div class="head-inner">

			<router-link :to="$link('', $store.getters['context'].url, language.code)" class="head-select-item" :class="{'is-active': language.code === $store.getters['language'].code}" v-bgimage="language.flag" v-for="(language, index) in head.languages" :key="index"></router-link>

		</div>

	</div>

	<div class="head-bar is-primary">

		<div class="head-inner">

			<router-link :to="$link('', $store.getters['context'].url, $store.getters['language'].code)" class="head-logo"></router-link>

			<div class="head-menu" v-on:mouseleave="onLeave">

				<template v-for="(page, index) in primaryTop">

					<router-link v-if="page.url !== '#'" :to="$link(page.url)" class="head-menu-item" v-on:mouseenter.native="onOver(page.id)" :key="index"><span>{{ page.name }}</span><com-submenu v-on:mouseenter.native="onOver(page.id)" :pages="submenuPages('primary', page.id)" :is-expanded="over === page.id" v-if="page.parent" class="head-menu-item-submenu" /></router-link>
					<div v-if="page.url === '#'" class="head-menu-item" v-on:mouseenter="onOver(page.id)" :key="index"><span>{{ page.name }}</span> <com-submenu v-on:mouseenter.native="onOver(page.id)" :pages="submenuPages('primary', page.id)" :is-expanded="over === page.id" v-if="page.parent" class="head-menu-item-submenu" /></div>

				</template>

			</div>

			<div class="head-search" v-on:click="$emit('search')">

				<app-icon s="search" :inline="true" /> Search

			</div>

			<div class="head-phone">

				<app-icon s="phone" :inline="true" /> Helpline <b>{{ $store.getters['context'].phone }}</b> 

			</div>

			<div class="head-language" v-on:click="selecting = !selecting">

				<div class="head-language-flag" v-bgimage="$store.getters['language'].flag" />
				<app-icon s="chevronDown" class="head-language-chevron" v-if="!selecting" />
				<app-icon s="chevronUp" class="head-language-chevron" v-if="selecting" />

			</div>

		</div>

	</div>

	<div class="head-bar is-secondary">

		<div class="head-inner">

			<div class="head-menu" v-on:mouseleave="onLeave">

				<template v-for="(page, index) in secondaryTop">

					<router-link v-if="page.url !== '#'" :to="$link(page.url)" class="head-menu-item" v-on:mouseenter.native="onOver(page.id)" :key="index"><span>{{ page.name }}</span> <com-submenu v-on:mouseenter.native="onOver(page.id)" :pages="submenuPages('secondary', page.id)" :is-expanded="over === page.id" v-if="page.parent" class="head-menu-item-submenu" /></router-link>
					<div v-if="page.url === '#'" class="head-menu-item" v-on:mouseenter="onOver(page.id)" :key="index"><span>{{ page.name }}</span> <com-submenu v-on:mouseenter.native="onOver(page.id)" :pages="submenuPages('secondary', page.id)" :is-expanded="over === page.id" v-if="page.parent" class="head-menu-item-submenu" /></div>

				</template>

			</div>

			<div class="head-donate" v-if="head.donate">

				<router-link :to="$link(head.donate)" class="head-donate-link">Donate</router-link>

			</div>

		</div>

	</div>

</div>

</template>

<script>

export default {

	props: ['head'],

	components: {
		'com-submenu': () => import('./Submenu')
	},

	data: function() {

		return {
			timer: false,
			selecting: false,
			over: false
		}

	},

	watch: {

		path: function() {

			this.over = false
			this.selecting = false

		}

	},

	computed: {

		primaryTop: function() {

			return this.$_.filter(this.head.primary, function(page) {

				return page.parent === true || page.parent === false

			})

		},

		secondaryTop: function() {

			return this.$_.filter(this.head.secondary, function(page) {

				return page.parent === true || page.parent === false

			})

		}

	},

	methods: {

		onOver: function(id) {

			clearTimeout(this.timer)
			this.over = id

		},

		onLeave: function() {

			this.timer = setTimeout(this.onLeaveComplete.bind(this), 500)

		},

		onLeaveComplete: function() {

			this.over = false

		},

		languagePath: function(code) {

			var path = this.$route.path

			path = path.replace(this.$store.getters['language'].code.toLowerCase() + '/', '')
			path = (path === '/' + this.$store.getters['language'].code.toLowerCase()) ? '' : path
			path = path.replace(this.$store.getters['context'].url.toLowerCase() + '/', '')
			path = (path === '/' + this.$store.getters['context'].url.toLowerCase()) ? '' : path

			var appendContext = false
			
			if (this.$store.getters['context'].url !== 'aa' && '/' + this.$store.getters['context'].url !== path) appendContext = true

			if (code !== 'UK') path = '/' + code.toLowerCase() + path

			if (appendContext) path = '/' + this.$store.getters['context'].url + path

			if (!path) path = '/'

			return path

		},

		submenuPages: function(type, id) {

			return this.$_.filter(this.head[type], function(page) {

				return page.parent === id

			})
		
		}

	}

}

</script>

<style scoped>

.head {
	position: fixed;
	z-index: 100;
	top: 0px;
	width: 100%;
	border-bottom: 1px solid #f5f5f5;
	transition: all 100ms linear;
	transform: translateY(calc(0px - (100% - 113px)));
}

.head.is-selecting {
	transform: translateY(0px);
}

.head-bar.is-primary {
	height: 32px;
	background-color: #1a234c;
}

.head-bar.is-secondary {
	height: 80px;
	background-color: #fff;
}

.head-inner {
	max-width: 1120px;
	margin: 0px auto;
	display: flex;
}

.head-logo {
	display: block;
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 2;
	width: 167px;
	height: 112px;
	background-color: #fff;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: contain;
}

.is-aa .head-logo {
	background-image: url(~@/assets/logo.aa.png);
}

.is-afa .head-logo {
	background-image: url(~@/assets/logo.afa.png);
}

.is-stars .head-logo {
	background-image: url(~@/assets/logo.stars.png);
}

.head-search {
	background-color: #53586E;
	color: #fff;
	font-size: 13px;
	letter-spacing: 1.3px;
	line-height: 32px;
	text-transform: uppercase;
	padding: 0px 15px;
	margin-left: 10px;
	cursor: pointer;
}

.head-search:hover {
	background-color: #444;
}

.head-phone {
	background-color: #1277D4;
	color: #fff;
	font-size: 13px;
	letter-spacing: 1.3px;
	line-height: 32px;
	text-transform: uppercase;
	padding: 0px 10px;
}

.head-phone b {
	letter-spacing: 0px;
}

.head-menu {
	display: flex;
	flex-grow: 1;
	justify-content: flex-end;
}

.head-bar.is-primary .head-menu-item {
	height: 32px;
	color: #fff;
	display: block;
	padding: 0px 10px;
}

.head-bar.is-primary .head-menu-item > span {
	text-transform: uppercase;
	line-height: 32px;
	font-size: 13px;
	letter-spacing: 1.3px;
}

.head-bar.is-primary .head-menu-item.router-link-active {
	background-color: #1277D4;
}

.head-bar.is-secondary .head-menu-item {
	height: 80px;
	display: block;
	padding: 0px 20px;
	border-left: 1px solid #f5f5f5;
	border-bottom: 6px solid #fff;
}

.head-bar.is-secondary .head-menu-item > span {
	font-size: 18px;
	color: #1A234C;
	line-height: 110px;
}

.head-bar.is-secondary .head-menu-item.router-link-active {
	border-bottom-color: #1277D4;
}

.head-language {
	height: 48px;
	width: 68px;
	background-color: #A9A8A0;
	z-index: 2;
	border-radius: 0px 0px 16px 16px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
}

.head-language-flag {
	width: 26px;
	height: 26px;
	background-position: 50% 50%;
	background-size: cover;
	border-radius: 50%;
}

.head-language-chevron {
	color: #fff;
	font-size: 16px;
	margin-left: 8px;
}

.head-donate {
	padding-left: 20px;
	border-left: 1px solid #f5f5f5;
}

.head-donate-link {
	height: 36px;
	display: block;
	background-color: #C21920;
	text-transform: uppercase;
	color: #fff;
	padding: 0px 20px;
	line-height: 36px;
	font-size: 16px;
	font-weight: bold;
	border-radius: 19px;
	margin-top: 30px;
}

.head-bar.is-primary .head-menu-item-submenu {
	top: 32px;
}

.head-bar.is-secondary .head-menu-item-submenu {
	top: 81px;
}

.head-select {
	background-color: #A9A8A0;
}

.head-select .head-inner {
	justify-content: flex-end;
	padding: 5px 0px;
	flex-wrap: wrap;
}

.head-select-item {
	height: 34px;
	width: 48px;
	display: flex;
	align-items: center;
	cursor: pointer;
	border: 4px solid #A9A8A0;
	background-position: 50% 50%;
	background-size: cover;
}

.head-select-item.is-active {
	border: 4px solid #1277D4;
}

</style>
